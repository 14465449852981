import React from "react";
import "pure-react-carousel/dist/react-carousel.es.css";
import { YearsContainer, ImageYearsDiv } from "./styles.js";
import ImageYears from "../../assets/years.png";

function Years() {
	return (
		<YearsContainer>
			<ImageYearsDiv src={ImageYears} />
		</YearsContainer>
	);
}
export default Years;
