import styled from "styled-components";

export const ProduzindoContainer = styled.div`
	width: 100%;
	padding: 0;
	margin: 0 auto;
`;

export const ProduzindoImageDiv = styled.img`
	width: 100%;
	padding: 0;
	margin: 0 auto;
`;
