import styled from "styled-components";
import colors from "../../styles/colors";

export const SobreNosContainer = styled.div`
  max-width: 1020px;
  width: 100%;
  padding: 0;
  margin: 0 auto;
  font-family: "roboto";
`;

export const Title = styled.h1`
  color: ${colors.titleText};
  text-align: center;
  background: ${colors.white};
  padding: 20px;
  border-radius: 24px;
  font-size: 43px;
  font-weight: 700;
  font-family: "roboto";
  margin: -90px auto 0 auto;
  z-index: 3000;
  position: relative;
  width: 100%;
  max-width: 450px;

  @media screen and (max-width: 900px) {
    font-size: 35px;
    width: 90%;
    margin: 40px auto 0 auto;
  }
`;

export const Description = styled.p`
  width: 100%;
  cursor: pointer;
  color: ${colors.titleText};
  text-align: center;
  font-size: 26px;
  font-weight: 400;
  line-height: 47px;
  margin: 50px auto 0 auto;
  @media screen and (max-width: 900px) {
    font-size: 20px;
    width: 90%;
    margin: 25px auto 0 auto;
  }
`;

export const Aux = styled.div`
  max-width: 1020px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 60px auto;

  @media screen and (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const Block = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 25%;

  @media screen and (max-width: 900px) {
    width: 90%;
    margin: 35px 0;
  }
`;

export const ImageSobre = styled.img`
  @media screen and (max-width: 900px) {
  }
`;

export const AuxImage = styled.div`
  height: 80px;
  margin: 0 0 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 900px) {
  }
`;

export const TitleBlock = styled.div`
  text-align: center;
  font-size: 36px;
  font-weight: 700;
  color: ${colors.titleText};
  margin: 0 0 15px;

  @media screen and (max-width: 900px) {
  }
`;

export const DescriptionBlock = styled.div`
  text-align: center;
  font-size: 19px;
  font-weight: 400;
  color: ${colors.titleText};

  @media screen and (max-width: 900px) {
  }
`;
