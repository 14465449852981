import styled from 'styled-components';

export const TradicaoContainer = styled.div`
  width: 100%;
  padding: 0;
  margin: 0 auto;
`;

export const ImageTradicaoDiv = styled.img`
  width: 100%;
  padding: 0;
  margin: 0 auto;
`;
