import React from "react";
import "pure-react-carousel/dist/react-carousel.es.css";
import {
	ServicesContainer,
	BlockService,
	SubTitleBlock,
	Description,
	TitleBlock,
	AltTitleBlock,
} from "./styles.js";
import IconCheck from "../../assets/check.svg";
import IconRocket from "../../assets/rocket.svg";
import IconTools from "../../assets/tools.svg";
import { IconButton } from "@material-ui/core";

function Years() {
	return (
		<ServicesContainer>
			<AltTitleBlock>Serviços</AltTitleBlock>
			<BlockService>
				<img src={IconCheck} alt="" />
				<SubTitleBlock>Consultoria completa no pré venda</SubTitleBlock>
				<Description>
					Possuímos know-how para propor a melhor solução para seu projeto,
					auxiliando o desenvolvimento completo da sua indústria.
				</Description>
			</BlockService>
			<BlockService className="border">
				<TitleBlock>Serviços</TitleBlock>
				<img src={IconRocket} alt="" />
				<SubTitleBlock>Instalação e startup do planejamento</SubTitleBlock>
				<Description>
					Temos uma equipe qualificada para realizar a instalação dos
					equipamentos, garantido total segurança na sua operação. Realizamos
					também o treinamento de toda a equipe responsável por operar os
					equipamentos.
				</Description>
			</BlockService>
			<BlockService>
				<img src={IconTools} alt="" />
				<SubTitleBlock>Departamento de manutenção</SubTitleBlock>
				<Description>
					Contamos com equipe técnica especializada para oferecer todo o suporte
					na manutenção dos equipamentos forma rápida e eficaz.
				</Description>
			</BlockService>
		</ServicesContainer>
	);
}
export default Years;
