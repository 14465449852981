import React from "react";
import {
	AuxImage,
	Block,
	Button,
	Description,
	ImageBlock,
	Info,
	InfoTwo,
	TitleProduct,
	Content,
} from "./styles";

function Product({ title, description, image, index, url }) {
	const Conteudo = () => (
		<>
			<TitleProduct>{title}</TitleProduct>
			<Description dangerouslySetInnerHTML={{ __html: description }} />
			<Button>
				<a href={url} style={{ color: "white", textDdecoration: "none" }}>
					Veja Mais
				</a>
			</Button>
		</>
	);
	return (
		<>
			<Block>
				<AuxImage>
					<ImageBlock src={image} />
				</AuxImage>
				{index % 2 === 0 ? (
					<Info>
						<Content>
							<Conteudo />
						</Content>
					</Info>
				) : (
					<InfoTwo>
						<Content>
							<Conteudo />
						</Content>
					</InfoTwo>
				)}
			</Block>
		</>
	);
}

export default Product;
