import styled from "styled-components";
import colors from "../../styles/colors";

export const ProductsContainer = styled.div`
	width: 100%;
	background-color: ${colors.blueMenu};

	@media screen and (max-width: 900px) {
		height: auto;
		background-color: ${colors.blueMenu};
	}
`;
export const Title = styled.h1`
	color: ${colors.titleText};
	text-align: center;
	background: ${colors.white};
	padding: 20px;
	border-radius: 24px;
	font-size: 43px;
	font-weight: 700;
	font-family: "roboto";
	margin: -90px auto 0 auto;
	z-index: 3000;
	position: relative;
	width: min-content;
	white-space: nowrap;

	@media screen and (max-width: 900px) {
		font-size: 35px;
		width: 90%;
		margin: 40px auto 0 auto;
		transform: translateY(15px);
	}
`;
export const Indent = styled.div`
	max-width: 1250px;
	width: 100%;
	padding: 0;
	margin: 0 auto;
	text-align: center;
	overflow: hidden;
`;

export const TitleProduct = styled.h2`
	font-size: 40px;
	color: ${colors.titlePerfiladeiras};
	font-weight: 700;
	margin: 0 0 20px 0;

	@media screen and (max-width: 900px) {
	}
`;
export const Description = styled.p`
	font-size: 23px;
	color: ${colors.titlePerfiladeiras};
	font-weight: 400;
	line-height: 41px;
	margin: 0 0 20px 0;

	@media screen and (max-width: 900px) {
	}
`;
export const Button = styled.button`
	max-width: 152px;
	width: 100%;
	padding: 15px 0;
	border: none;
	color: ${colors.white};
	background: ${colors.orange};
	margin: 10px 0 0 0;
	font-size: 17px;
	text-transform: uppercase;
	text-align: center;
	font-weight: 500;
	cursor: pointer;
	border-radius: 5px;
	align-self: flex-end;
	transition: all 0.5s;

	&:hover {
		transform: scale(1.2);
		background: #ff9337;
	}
`;
export const Block = styled.div`
	width: 100%;
	position: relative;
	display: flex;
	position: relative;

	@media screen and (max-width: 900px) {
		width: 90%;
		justify-content: center;
		align-items: center;
		display: flex;
		flex-direction: column;
	}
`;
export const AuxImage = styled.div`
	width: 50%;
	height: 570px;

	@media screen and (max-width: 1320px) {
	}

	@media screen and (max-width: 1020px) {
		width: 100%;
		height: auto;
	}
`;
export const ImageBlock = styled.img`
	height: 570px;

	@media screen and (max-width: 1320px) {
		width: 100%;
		height: auto;
	}
`;
export const Info = styled.div`
	width: 65%;
	height: auto;
	background: ${colors.backgroundServices};
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 25px 50px;

	@media screen and (max-width: 1020px) {
		width: 100%;
		height: inherit;
	}
`;
export const Content = styled.div`
	width: 75%;
	display: flex;
	flex-direction: column;

	@media screen and (max-width: 900px) {
		width: 100%;
	}
`;
export const InfoTwo = styled.div`
	width: 65%;
	height: auto;
	background: ${colors.backgroundPerfiladeiras};
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 25px 50px;

	@media screen and (max-width: 1020px) {
		width: 100%;
		height: inherit;
	}
`;
