import React, { useState } from "react";
import Logo from "../../assets/logo.png";
import FaceIcon from "../../assets/face-icon.png";
import InstaIcon from "../../assets/insta-icon.png";
import WhatsIcon from "../../assets/whats-icon.png";
import YoutubeIcon from "../../assets/youtube-icon.png";
import emailjs from "emailjs-com";
import LoadingGif from "../../assets/loading.gif";
import {
	FooterContainer,
	LogoImage,
	ColumnFooter,
	Address,
	AuxIcon,
	IconImage,
	Title,
	Links,
	Input,
	Button,
	TextNews,
	IndentFooter,
	ColumnLinks,
	AuxLoading,
	Modal,
	TextModal,
	ButtonModal,
	Loading,
	TextButton,
} from "./styles.js";

function Footer() {
	const [loading, setLoading] = useState(false);
	const [modalSuccess, setModalSuccess] = useState(false);
	const [modalError, setModalError] = useState(false);

	const userID = process.env.REACT_APP_USER_ID;
	const templateID = process.env.REACT_APP_TEMPLATE_NEWS_ID;
	const serviceID = process.env.REACT_APP_SERVICE_ID;

	function sendEmail(e) {
		e.preventDefault();
		setLoading(true);

		emailjs.sendForm(serviceID, templateID, e.target, userID).then(
			(result) => {
				setLoading(false);
				e.target.reset();
				setModalSuccess(true);
			},
			(error) => {
				setLoading(false);
				setModalError(true);
			}
		);
	}

	function sendEmailAPI(e) {
		e.preventDefault();
		setLoading(true);
		fetch(process.env.REACT_APP_URL_API + "/formCadastro", {
			method: "POST",
			body: new FormData(e.target),
		})
			.then(() => {
				setLoading(false);
				e.target.reset();
				setModalSuccess(true);
			})
			.catch(() => {
				setLoading(false);
				setModalError(true);
			});
	}

	function closeModal() {
		setModalError(false);
		setModalSuccess(false);
	}

	return (
		<FooterContainer>
			<IndentFooter>
				<ColumnFooter>
					<a href="/">
						<LogoImage src={Logo} />
					</a>
					<AuxIcon>
						<a
							href="https://www.facebook.com/perfiladeirasmetalso"
							target="_blank"
						>
							<IconImage src={FaceIcon} alt="metalso" />
						</a>
						<a
							href="https://www.instagram.com/perfiladeirasmetalso/"
							target="_blank"
						>
							<IconImage src={InstaIcon} alt="metalso" />
						</a>
						<a
							href="https://api.whatsapp.com/send?phone=551934514497"
							target="_blank"
						>
							<IconImage src={WhatsIcon} alt="metalso" />
						</a>
						<a
							href="https://youtube.com/channel/UCzwF60oRGUhNewbD7cGczsg"
							target="_blank"
						>
							<IconImage src={YoutubeIcon} alt="metalso" />
						</a>
					</AuxIcon>
				</ColumnFooter>
				<ColumnLinks>
					<Title>Links</Title>
					<Links href="/">Home</Links>
					<Links href="/produtos">Produtos</Links>
					<Links href="/sobre">Sobre</Links>
					<Links href="/contato">Contato</Links>
				</ColumnLinks>
				<ColumnFooter>
					<Title>Receba novidades</Title>

					<form className="form" onSubmit={sendEmailAPI}>
						<Input placeholder="Email" type="email" name="email" required />

						<AuxLoading>
							<Button type="submit">Inscrever</Button>
							{loading && (
								<Loading>
									<img src={LoadingGif} />
								</Loading>
							)}
						</AuxLoading>
					</form>
				</ColumnFooter>

				{modalSuccess && (
					<Modal>
						<TextModal>Mensagem enviada com sucesso!</TextModal>
						<ButtonModal onClick={closeModal}>
							<TextButton>Ok</TextButton>
						</ButtonModal>
					</Modal>
				)}

				{modalError && (
					<Modal>
						<TextModal>
							Não foi possível enviar sua mensagem, tente novamente mais tarde!
						</TextModal>
						<ButtonModal onClick={closeModal}>
							<TextButton>Ok</TextButton>
						</ButtonModal>
					</Modal>
				)}
			</IndentFooter>
		</FooterContainer>
	);
}
export default Footer;
