// import Header from "../components/Header";
import Banner from "../components/Banner";
import QuemSomos from "../components/QuemSomos";
import ProductsLine from "../components/ProductsLine";
import Years from "../components/Years";
import Services from "../components/Services";
import Payment from "../components/Payment";
import Location from "../components/Location";

export default function Home() {
  return (
    <div>
      <Banner />
      <QuemSomos />
      <ProductsLine />
      <Years />
      <Services />
      <Payment />
      <Location />
    </div>
  );
}
