import React from 'react';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { LocationContainer, Title, Map } from './styles.js';

function Location() {
  return (
    <LocationContainer>
      <Title>NOSSA LOCALIZAÇÃO</Title>
      <Map>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3684.8540683314623!2d-47.41325898486703!3d-22.54713873080531!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c8807fb8b1a909%3A0xccdf65f5c8b3d25d!2sR.%20Jos%C3%A9%20Mendes%2C%2063%20-%20Jardim%20Santo%20Andre%2C%20Limeira%20-%20SP%2C%2013484-112!5e0!3m2!1sen!2sbr!4v1655406302726!5m2!1sen!2sbr"
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </Map>
    </LocationContainer>
  );
}
export default Location;
