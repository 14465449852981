import styled from "styled-components";
import colors from "../../styles/colors";

export const FooterContainer = styled.div`
	width: 100%;
	padding: 50px 0;
	background-color: ${colors.backgroundFooter};
	font-family: "roboto";

	.form {
		width: 100%;
		display: flex;
		align-items: center;
		flex-direction: column;
	}
`;

export const IndentFooter = styled.div`
	display: flex;
	justify-content: center;
	align-items: flex-start;
	max-width: 1250px;
	width: 100%;
	background-color: ${colors.backgroundFooter};
	margin: 0 auto;
	position: relative;

	@media screen and (max-width: 900px) {
		flex-direction: column;
		flex-direction: center;
		align-items: center;
	}
`;

export const LogoImage = styled.img`
	width: 100%;
	max-width: 300px;
`;

export const ColumnFooter = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin: 0 40px 0 0;
	width: 30%;

	@media screen and (max-width: 900px) {
		flex-direction: column;
		flex-direction: center;
		align-items: center;
		margin: 0;
		width: 90%;
	}
`;

export const ColumnLinks = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
	margin: 0 40px 0 0;
	width: 10%;

	@media screen and (max-width: 900px) {
		flex-direction: column;
		flex-direction: center;
		align-items: center;
		margin: 40px 0 0 0;
		width: 90%;
	}
`;

export const Address = styled.p`
	color: ${colors.textColor};
	font-size: 16px;
	font-weight: 400;
	line-height: 30px;
	text-align: center;
	margin: 30px 0 15px 0;
	width: 65%;
`;

export const AuxIcon = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const IconImage = styled.img`
	margin: 10px;
	width: 35px;
	height: 35px;
`;

export const Title = styled.h2`
	color: ${colors.textColor};
	font-size: 22px;
	font-weight: bold;
	line-height: 33px;
	text-align: left;
	margin: 0 0 40px 0;

	@media screen and (max-width: 900px) {
		margin: 40px 0 20px 0;
	}
`;

export const Links = styled.a`
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	text-align: left;
	font-weight: 400;
	color: ${colors.secondaryText};
	&:not(:last-child) {
		margin-bottom: 15px;
	}
`;

export const Input = styled.input`
	max-width: 300px;
	width: 100%;
	height: 50px;
	border: none;
	color: ${colors.secondaryText};
	border-radius: 3px;
	padding: 0 0 0 30px;
`;

export const Button = styled.button`
	max-width: 330px;
	width: 100%;
	height: 50px;
	border: none;
	color: ${colors.white};
	border-radius: 3px;
	background: ${colors.orange};
	margin: 10px 0 0 0;
	font-size: 17px;
	text-transform: uppercase;
	text-align: center;
	font-weight: 300;
	cursor: pointer;
`;

export const TextNews = styled.p`
	color: ${colors.textColor};
	font-size: 17px;
	font-weight: 400;
	line-height: 32px;
	text-align: left;
	margin: 10px 0 0 0;
	width: 100%;
	max-width: 300px;

	@media screen and (max-width: 900px) {
		text-align: center;
	}
`;

export const AuxLoading = styled.div`
	display: flex;
	align-items: flex-start;
	width: 100%;
	max-width: 300px;

	@media screen and (max-width: 900px) {
	}
`;

export const Modal = styled.div`
	position: absolute;
	right: 40%;
	top: 50%;
	background-color: ${colors.white};
	border-radius: 10px;
	padding: 10px 20px;
	box-shadow: 0 0px 5px ${colors.secondaryText};

	@media screen and (max-width: 900px) {
		width: 90%;
		right: 5%;
		top: 70%;
	}
`;

export const TextModal = styled.p`
	font-size: 20px;
	font-weight: 500;
	text-align: center;
	@media screen and (max-width: 900px) {
	}
`;

export const ButtonModal = styled.button`
	border: none;
	height: 30px;
	background: ${colors.orange};
	width: 70px;
	border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 20px auto 0 auto;
	color: ${colors.white};
	font-size: 16px;
	font-weight: 400;
	cursor: pointer;
	position: relative;
	@media screen and (max-width: 900px) {
	}
`;

export const Loading = styled.div`
	@media screen and (max-width: 900px) {
	}
`;

export const TextButton = styled.p`
	text-align: center;
	display: flex;
	justify-content: center;
	margin: 0;

	@media screen and (max-width: 900px) {
	}
`;
