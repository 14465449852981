import React, { useState, useEffect } from "react";
import MenuBar from "../MenuBar";
import MenuMobile from "../MenuMobile";
import { HeaderContainer, Indent } from "./styles.js";

const Header = () => {
  const [widthResolution, setWidthResolution] = useState(0);

  useEffect(() => {
    setWidthResolution(window.innerWidth);
  }, []);

  function getMenu() {
    if (widthResolution < 948) {
      return (
        <>
          <MenuMobile />
        </>
      );
    } else {
      return (
        <>
          <Indent>
            <MenuBar />
          </Indent>
        </>
      );
    }
  }

  return <HeaderContainer>{getMenu()}</HeaderContainer>;
};

export default Header;
