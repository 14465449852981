import styled from "styled-components";
import colors from "../../styles/colors";

export const PaymentContainer = styled.div`
	width: 100%;
	padding: 50px 0;
	margin: 0 auto;
	background: ${colors.backgroundPayment};
	box-shadow: 0px 20px 35px ${colors.backgroundFooter};

	.bnds {
		width: 100%;
		max-width: 250px;
	}

	.finame {
		width: 100%;
		max-width: 250px;
	}

	.financiamento {
		width: 100%;
		max-width: 250px;
	}

	.santander {
		width: 100%;
		max-width: 335px;
	}
`;

export const Title = styled.div`
	color: ${colors.orange};
	font-size: 43px;
	font-weight: 700;
	text-align: center;
	font-family: roboto;
	padding: 5px;
	max-width: 550px;
	width: 100%;
	margin: 0 auto;

	@media screen and (max-width: 900px) {
		font-size: 30px;
		max-width: 320px;
	}
`;

export const AuxImages = styled.div`
	width: 100%;
	max-width: 1250px;
	margin: 35px auto;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	height: 150px;

	@media screen and (max-width: 1100px) {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: 250px 0;
	}
`;

export const LogoImage = styled.img`
	width: 100%;

	@media screen and (max-width: 900px) {
		margin: 40px 0;
	}
`;
