import React, { useState } from "react";
import "pure-react-carousel/dist/react-carousel.es.css";
import emailjs from "emailjs-com";
import {
	FormularioContainer,
	Title,
	Map,
	Description,
	Indent,
	AuxInput,
	Input,
	InputArea,
	Button,
	TextButton,
	Loading,
	AuxLoading,
	Modal,
	TextModal,
	ButtonModal,
} from "./styles.js";
import LoadingGif from "../../assets/loading.gif";

function Formulario() {
	const [loading, setLoading] = useState(false);
	const [modalSuccess, setModalSuccess] = useState(false);
	const [modalError, setModalError] = useState(false);

	const userID = process.env.REACT_APP_USER_ID;
	const templateID = process.env.REACT_APP_TEMPLATE_ID;
	const serviceID = process.env.REACT_APP_SERVICE_ID;

	function sendEmail(e) {
		e.preventDefault();
		setLoading(true);

		emailjs.sendForm(serviceID, templateID, e.target, userID).then(
			(result) => {
				console.log(result.text);
				setLoading(false);
				e.target.reset();
				setModalSuccess(true);
			},
			(error) => {
				console.log(error.text);
				setLoading(false);
				setModalError(true);
			}
		);
	}

	function sendEmailAPI(e) {
		e.preventDefault();
		setLoading(true);
		fetch(process.env.REACT_APP_URL_API + "/formCadastro", {
			method: "POST",
			body: new FormData(e.target),
		})
			.then(() => {
				setLoading(false);
				e.target.reset();
				setModalSuccess(true);
			})
			.catch(() => {
				setLoading(false);
				setModalError(true);
			});
	}

	function closeModal() {
		setModalError(false);
		setModalSuccess(false);
	}

	return (
		<FormularioContainer>
			<Title>CONTATO</Title>
			<Description>
				Entre em contato e receba todo o atendimento técnico que você precisa.
				Conte com quem tem 30 anos de experiência!
			</Description>
			<Indent>
				<Map>
					<iframe
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3684.8540683314623!2d-47.41325898486703!3d-22.54713873080531!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c8807fb8b1a909%3A0xccdf65f5c8b3d25d!2sR.%20Jos%C3%A9%20Mendes%2C%2063%20-%20Jardim%20Santo%20Andre%2C%20Limeira%20-%20SP%2C%2013484-112!5e0!3m2!1sen!2sbr!4v1655406302726!5m2!1sen!2sbr"
						width="100%"
						height="460"
						style={{ border: 0 }}
						allowFullScreen=""
						loading="lazy"
						referrerPolicy="no-referrer-when-downgrade"
						className="map"
					></iframe>
				</Map>
				<form className="form" onSubmit={sendEmailAPI}>
					<AuxInput>
						<Input
							type="text"
							name="name"
							placeholder="Nome Completo"
							required
						/>
						<Input type="text" name="phone" placeholder="Telefone" required />
					</AuxInput>

					<AuxInput>
						<Input type="email" name="email" placeholder="Email" required />

						<Input type="text" name="subject" placeholder="Assunto" required />
					</AuxInput>

					<InputArea name="message" placeholder="Mensagem" />

					<AuxLoading>
						<Button type="submit" value="Enviar">
							<TextButton>Enviar</TextButton>
						</Button>
						{loading && (
							<Loading>
								<img src={LoadingGif} alt="metalso" />
							</Loading>
						)}
					</AuxLoading>
				</form>

				{modalSuccess && (
					<Modal>
						<TextModal>Mensagem enviada com sucesso!</TextModal>
						<ButtonModal onClick={closeModal}>
							<TextButton>Ok</TextButton>
						</ButtonModal>
					</Modal>
				)}

				{modalError && (
					<Modal>
						<TextModal>
							Não foi possível enviar sua mensagem, tente novamente mais tarde!
						</TextModal>
						<ButtonModal onClick={closeModal}>
							<TextButton>Ok</TextButton>
						</ButtonModal>
					</Modal>
				)}
			</Indent>
		</FormularioContainer>
	);
}
export default Formulario;
