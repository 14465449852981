export const colors = {
  headerBackground: "#F2F2F2",
  white: "#FFFFFF",
  black: "#000",
  blueMenu: "#00509E",
  grayMenu: "#D9D9D9",
  textColor: "#484848",
  secondaryText: "#1F242C",
  orange: "#EE7005",
  backgroundFooter: "#E7E7E7",
  titleText: "#1F242C",
  grey: "#AEAEAE",
  colorProduct: "#002344",
  backgroundServices: "#F3F3F3",
  border: "#3E3E3E",
  backgroundPayment: "#FBFBFB",
  borderInput: "#084C94",
  placeholderInput: "#091D3E",
  backgroundPerfiladeiras: "#E1E1E1",
  titlePerfiladeiras: "#2C2C2C",
  colorCheck: "#F8F8F8",
};

export default colors;
