import styled from "styled-components";
import colors from "../../styles/colors";

export const LocationContainer = styled.div`
  width: 100%;
  padding: 0;
  margin: 50px auto 0 auto;
`;

export const Title = styled.div`
  color: ${colors.orange};
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  margin: 0 auto 50px auto;
  font-family: "roboto";
`;

export const Map = styled.div``;
