import styled from "styled-components";

export const AuxImages = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin: 15px auto;

	@media screen and (max-width: 900px) {
		align-items: center;
		flex-direction: column;
	}
`;

export const ImageLink = styled.a`
	width: 90%;
	transition: all 0.5s;
	&:hover {
		transform: scale(1.2);
	}
	@media screen and (max-width: 900px) {
		max-width: 350px;
	}
`;

export const Image = styled.img`
	width: 100%;
`;
