import React from 'react';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { QuemSomosContainer, Title, Description, Link } from './styles.js';

function QuemSomos() {
  return (
    <QuemSomosContainer>
      <Title>QUEM SOMOS</Title>
      <Description>
        Situada no município de Limeira - SP, a empresa foi fundada em 1993, voltada para a construção de máquinas e
        equipamentos especiais para perfilação, corte e dobras de chapas. Sempre firmando parcerias com empresas sérias
        e altamente capacitadas para desenvolvimento de seus produtos, hoje tem como principal característica a
        construção de equipamentos personalizados que atendem a necessidade específica de seus clientes.
      </Description>
      <Link href="/sobre">Leia mais...</Link>
    </QuemSomosContainer>
  );
}
export default QuemSomos;
