import styled from "styled-components";
import colors from "../../styles/colors";

export const ProductsLineContainer = styled.div`
	width: 100%;

	@media screen and (max-width: 900px) {
		height: auto;
		background-color: ${colors.colorProduct};
	}
`;

export const Indent = styled.div`
	max-width: 1250px;
	width: 100%;
	padding: 40px 0 0 0;
	margin: 0 auto;
	text-align: center;
	overflow: hidden;
`;

export const Title = styled.h1`
	color: ${colors.white};
	font-size: 43px;
	font-weight: 700;
	text-align: center;
	font-family: roboto;
	background: ${colors.orange};
	padding: 5px;
	max-width: 450px;
	width: 100%;
	margin: 0 auto;

	@media screen and (max-width: 900px) {
		font-size: 30px;
		max-width: 320px;
	}
`;

export const SubTitle = styled.h2`
	color: ${colors.white};
	font-size: 29px;
	font-weight: 700;
	text-align: center;
	font-family: roboto;
	margin: 15px auto;
	@media screen and (max-width: 900px) {
		font-size: 20px;
	}
`;
