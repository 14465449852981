import styled from "styled-components";
import colors from "../../styles/colors";

export const FormularioContainer = styled.div`
	width: 100%;
	padding: 0;
	margin: 50px auto 100px auto;
	font-family: "roboto";
`;

export const Title = styled.div`
	color: ${colors.titleText};
	text-align: center;
	background: ${colors.white};
	padding: 20px;
	border-radius: 24px;
	font-size: 43px;
	font-weight: 700;
	font-family: "roboto";
	margin: -140px auto 0 auto;
	z-index: 3000;
	position: relative;
	width: 100%;
	max-width: 450px;

	@media screen and (max-width: 900px) {
		font-size: 35px;
		width: 90%;
		margin: 40px auto 0 auto;
	}
`;

export const Map = styled.div`
	width: 50%;
	@media screen and (max-width: 900px) {
		width: 100%;
		margin: 0 0 50px 0;
	}
`;

export const Description = styled.p`
	text-align: center;
	font-size: 22px;
	font-weight: 400;
	line-height: 40px;
	margin: 0 auto;
	width: 60%;

	@media screen and (max-width: 900px) {
		width: 90%;
		flex-direction: column;
	}
`;

export const Indent = styled.div`
	max-width: 1250px;
	width: 100%;
	padding: 40px 0 0 0;
	margin: 0 auto;
	text-align: center;
	display: flex;
	position: relative;

	.form {
		width: 45%;
		margin: 0 0 0 50px;
	}

	@media screen and (max-width: 900px) {
		width: 90%;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.form {
			width: 100%;
			margin: 0;
		}
	}
`;

export const AuxInput = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media screen and (max-width: 578px) {
		flex-direction: column;
	}
`;

export const Input = styled.input`
	border: 1px solid ${colors.borderInput};
	height: 70px;
	color: ${colors.placeholderInput};
	width: 48%;
	margin: 0 0 20px 0;
	display: flex;
	align-items: flex-start;
	padding: 10px;

	@media screen and (max-width: 578px) {
		width: 100%;
	}
`;

export const InputArea = styled.textarea`
	border: 1px solid ${colors.borderInput};
	height: 200px;
	color: ${colors.placeholderInput};
	width: 100%;
	padding: 10px;

	@media screen and (max-width: 900px) {
	}
`;

export const Button = styled.button`
	border: none;
	height: 50px;
	background: ${colors.orange};
	width: 230px;
	border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 20px 0 0 0;
	color: ${colors.white};
	font-size: 23px;
	font-weight: 400;
	cursor: pointer;
	position: relative;

	@media screen and (max-width: 900px) {
	}
`;

export const TextButton = styled.p`
	text-align: center;
	display: flex;
	justify-content: center;
	margin: 0;

	@media screen and (max-width: 900px) {
	}
`;

export const Loading = styled.div`
	@media screen and (max-width: 900px) {
	}
`;

export const AuxLoading = styled.div`
	display: flex;
	align-items: flex-start;

	@media screen and (max-width: 900px) {
	}
`;

export const Modal = styled.div`
	position: absolute;
	right: 40%;
	top: 50%;
	background-color: ${colors.white};
	border-radius: 10px;
	padding: 10px 20px;
	box-shadow: 0 0px 5px ${colors.secondaryText};

	@media screen and (max-width: 900px) {
		width: 100%;
		right: 0%;
		top: 70%;
	}
`;

export const TextModal = styled.p`
	font-size: 20px;
	font-weight: 500;
	@media screen and (max-width: 900px) {
	}
`;

export const ButtonModal = styled.button`
	border: none;
	height: 30px;
	background: ${colors.orange};
	width: 70px;
	border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 20px auto 0 auto;
	color: ${colors.white};
	font-size: 16px;
	font-weight: 400;
	cursor: pointer;
	position: relative;
	@media screen and (max-width: 900px) {
	}
`;
