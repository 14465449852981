import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import "pure-react-carousel/dist/react-carousel.es.css";
import {
	ProductsLineContainer,
	Title,
	SubTitle,
	Indent
} from "./styles.js";
import Categorias from "../Categorias/index.js";

function ProductsLine() {
	return (
		<ProductsLineContainer
			style={{ backgroundImage: `url("/background-product.jpg")` }}
		>
			<Indent>
				<Title>LINHA DE PRODUTOS</Title>
				<SubTitle>ESCOLHA UMA CATEGORIA</SubTitle>
				<Categorias />
			</Indent>
		</ProductsLineContainer>
	);
}
export default ProductsLine;
