import React, { useState, useEffect } from "react";
import "pure-react-carousel/dist/react-carousel.es.css";
import Product from "./Product";
import { ProductsContainer, Title, Indent } from "./styles.js";
import Categorias from "../Categorias";

import { useParams } from "react-router-dom";

const base_api = process.env.REACT_APP_URL_API;

function Products() {
	const { url } = useParams();
	const [title, setTitle] = useState("PRODUTOS");
	const [produtos, setProdutos] = useState([]);

	useEffect(() => {
		fetch(`${base_api}/produtos/${url ?? ""}`)
			.then((res) => res.json())
			.then((prod) =>
				setProdutos(
					prod.sort((p1, p2) => {
						const categoriasOrdem = { 1: 1, 2: 2, 3: 5, 4: 4, 5: 3 };
						return (
							categoriasOrdem[p1.categoria] - categoriasOrdem[p2.categoria]
						);
					})
				)
			)
			.catch(console.log);
	}, []);

	return (
		<>
			<ProductsContainer>
				<Title>{title}</Title>
				<Indent>
					<Categorias />
				</Indent>
			</ProductsContainer>

			{produtos.length > 0 ? (
				produtos.map((item, i) => (
					<Product
						title={item.titulo}
						description={item.linhafina}
						image={item.arquivo}
						url={item.url}
						key={i}
						index={i}
					/>
				))
			) : (
				<Title>Carregando...</Title>
			)}
		</>
	);
}
export default Products;
