import styled from "styled-components";
import colors from "../../styles/colors";

export const LogoImage = styled.img`
  width: 100%;
  max-width: 300px;
`;

export const Mobile = styled.div`
  display: none;

  @media screen and (max-width: 948px) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 80%;
  }
`;

export const LinkPage = styled.a`
  text-transform: uppercase;
  color: ${colors.secondaryText};
`;
