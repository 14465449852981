import React, { useEffect } from "react";
import "antd/dist/antd.css";
import { Menu } from "antd";
import { useState } from "react";
import { HeaderContainer, LogoImage, Indent } from "./styles.js";
import Logo from "../../assets/logo.png";

function getItem(label, key, link) {
  return {
    key,
    link,
    label,
  };
}

const MenuBar = () => {
  const [current, setCurrent] = useState("");

  const pathname = window.location.pathname;
  const onClick = (e) => {
    setCurrent(e.key);
    if (e.key === "1") {
      window.location.href = "/";
    } else if (e.key === "2") {
      window.location.href = "/sobre";
    } else if (e.key === "3") {
      window.location.href = "/produtos";
    } else if (e.key === "4") {
      window.location.href = "/contato";
    }
  };

  useEffect(() => {
    if (pathname === "/") {
      setCurrent("1");
    } else if (pathname === "/sobre") {
      setCurrent("2");
    } else if (pathname === "/produtos") {
      setCurrent("3");
    } else if (pathname === "/contato") {
      setCurrent("4");
    }
  }, [pathname]);

  const items = [
    getItem("HOME", "1", "home"),
    getItem("SOBRE NÓS", "2", "sobre"),
    getItem("PRODUTOS", "3", "produtos"),
    getItem("CONTATO", "4", "contato"),
  ];
  return (
    <HeaderContainer>
      <Indent>
        <a href="/">
          <LogoImage src={Logo} />
        </a>
        <Menu
          onClick={onClick}
          defaultOpenKeys={["sub1"]}
          selectedKeys={[current]}
          mode="horizontal"
          items={items}
        />
      </Indent>
    </HeaderContainer>
  );
};

export default MenuBar;
