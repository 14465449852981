import Banner from "../components/Banner";
import SobreNos from "../components/SobreNos";
import Produzindo from "../components/Produzindo";
import Payment from "../components/Payment";
import Location from "../components/Location";
import styles from "../styles/Home.module.css";

export default function Sobre() {
	return (
		<div className={styles.container}>
			<Banner />
			<SobreNos />
			<Banner />
			<Payment />
			<Location />
		</div>
	);
}
