import styled from 'styled-components';

export const YearsContainer = styled.div`
  width: 100%;
  padding: 0;
  margin: 0 auto;
`;

export const ImageYearsDiv = styled.img`
  width: 100%;
  padding: 0;
  margin: 0 auto;
`;
