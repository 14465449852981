import React from "react";

import Routes from "./routes";
import Header from "./components/Header";
import Footer from "./components/Footer";
import BotaoWhatsapp from "./components/BotaoWhatsapp";

export default function App() {
	return (
		<>
			<Header />
			<Routes />
			<Footer />
			<BotaoWhatsapp />
		</>
	);
}
