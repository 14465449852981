import React from "react";
import "pure-react-carousel/dist/react-carousel.es.css";
import { TradicaoContainer, ImageTradicaoDiv } from "./styles.js";
import TradicaoImage from "../../assets/tradicao.jpg";

function Tradicao() {
  return (
    <TradicaoContainer>
      <ImageTradicaoDiv src={TradicaoImage} />
    </TradicaoContainer>
  );
}
export default Tradicao;
