import React from "react";
import "pure-react-carousel/dist/react-carousel.es.css";
import Bnds from "../../assets/bnds.png";
import Finame from "../../assets/finame.png";
import Santander from "../../assets/santander.png";
import Financiamento from "../../assets/financiamento.png";
import { PaymentContainer, Title, AuxImages, LogoImage } from "./styles.js";

function Payment() {
  return (
    <PaymentContainer>
      <Title>Formas de pagamento</Title>
      <AuxImages>
        <LogoImage className="bnds" src={Bnds} />
        <LogoImage className="finame" src={Finame} />
        <LogoImage className="santander" src={Santander} />
        <LogoImage className="financiamento" src={Financiamento} />
      </AuxImages>
    </PaymentContainer>
  );
}
export default Payment;
