import styled from "styled-components";

export const BannerContainer = styled.div`
  width: 100%;
  padding: 0;
`;

export const ImageBanner = styled.img`
  width: 100%;
  cursor: pointer;
`;

export const Link = styled.a`
  text-decoration: none;
`;
