import React from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";

import Home from "./pages/home";
import Produtos from "./pages/produtos";
import Contato from "./pages/contato";
import Sobre from "./pages/sobre";
import ProdutosDetalhes from "./pages/produtosDetalhes";

function Routes() {
	return (
		<Router>
			<Switch>
				<Route path="/" exact component={Home} />
				<Route path="/produtos/:url" component={Produtos} />
				<Route path="/produtos" component={Produtos} />
				<Route path="/produto/:url" component={ProdutosDetalhes} />
				<Route path="/contato" component={Contato} />
				<Route path="/sobre" component={Sobre} />
			</Switch>
		</Router>
	);
}

export default Routes;
