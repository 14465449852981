import Banner from "../components/Banner";
import Payment from "../components/Payment";
import Location from "../components/Location";

import styles from "../styles/Home.module.css";
import ProductDetails from "../components/ProductDetails/index";

export default function Produtos() {
  return (
    <div className={styles.container}>
      <Banner />

      <ProductDetails />

      <Payment />
      <Location />
    </div>
  );
}
