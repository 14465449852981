import styled from "styled-components";
import colors from "../../styles/colors";

export const ServicesContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	font-family: "roboto";

	background: ${colors.backgroundServices};

	.border {
		border-right: 1px solid ${colors.border};
		border-left: 1px solid ${colors.border};
	}

	@media screen and (max-width: 900px) {
		flex-direction: column;

		.border {
			border: none;
		}
	}
`;

export const BlockService = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	flex-direction: column;
	padding: 150px 10px 60px 10px;
	position: relative;
	max-width: calc(100% / 3);
	@media screen and (max-width: 900px) {
		padding: 50px 10px;
		max-width: unset;
	}
`;

export const SubTitleBlock = styled.div`
	font-size: 26px;
	font-weight: 700;
	color: ${colors.orange};
	margin: 20px 0;
	width: 300px;
`;

export const Description = styled.div`
	font-size: 23px;
	font-weight: 400;
	color: ${colors.secondaryText};
	margin: 0;
	line-height: 35px;
`;

export const TitleBlock = styled.div`
	color: ${colors.white};
	font-size: 43px;
	font-weight: 700;
	text-align: center;
	font-family: roboto;
	background: ${colors.orange};
	padding: 5px;
	max-width: 300px;
	width: 100%;
	margin: 0 auto;
	position: absolute;
	top: 7%;

	@media screen and (max-width: 900px) {
		font-size: 30px;
		max-width: 320px;
		display: none;
	}
`;

export const AltTitleBlock = styled.div`
	color: ${colors.white};
	font-size: 43px;
	font-weight: 700;
	text-align: center;
	font-family: roboto;
	background: ${colors.orange};
	padding: 5px;
	max-width: 300px;
	width: 100%;
	margin: 50px auto 0 auto;
	position: static;
	top: 7%;
	display: none;

	@media screen and (max-width: 900px) {
		font-size: 30px;
		max-width: 320px;
		display: block;
	}
`;
