import Banner from "../components/Banner";
import Products from "../components/Products";
import Payment from "../components/Payment";
import Location from "../components/Location";
import styles from "../styles/Home.module.css";

export default function Produtos() {

  return (
    <div className={styles.container}>
      <Banner />
      <Products />
      <Payment />
      <Location />
    </div>
  );
}
