import styled from "styled-components";
import colors from "../../styles/colors";

export const PerfiladeiraDrywallContainer = styled.div`
	max-width: 1250px;
	width: 100%;
	padding: 0;
	margin: 0 auto;
	font-family: "roboto";
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;
export const Title = styled.h1`
	color: ${colors.titleText};
	text-align: center;
	background: ${colors.white};
	padding: 20px;
	border-radius: 24px;
	font-size: 43px;
	font-weight: 700;
	font-family: "roboto";
	margin: -90px auto 0 auto;
	z-index: 3000;
	position: relative;
	max-width: 75%;

	@media screen and (max-width: 900px) {
		font-size: 20px;
		width: 90%;
		margin: 40px auto 0 auto;
	}
`;
export const Aux = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin: 30px auto;
	column-gap: 50px;
	max-width: 90%;
	@media screen and (max-width: 900px) {
		flex-direction: column;
	}
`;
export const Block = styled.div`
	width: 50%;
	position: relative;

	@media screen and (max-width: 900px) {
		width: 90%;
		justify-content: center;
		align-items: center;
		display: flex;
		flex-direction: column;
	}
`;
export const ImageSteelFrame = styled.img`
	//margin: 20px;
	width: 33%;
	object-fit: contain;
	@media screen and (max-width: 635px) {
	}
`;
export const AuxImages = styled.div`
	margin: 50px auto 0 auto;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	column-gap: 25px;

	@media screen and (max-width: 900px) {
		align-items: center;
		flex-direction: column;
	}
`;
export const AuxText = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin: 0 0 10px 0;
	@media screen and (max-width: 900px) {
	}
`;
export const ImageRight = styled.img`
	width: 20px;
	margin: 0 10px 0 0;
	@media screen and (max-width: 900px) {
	}
`;
export const ImageSteelFrameBig = styled.img`
	width: 100%;

	@media screen and (max-width: 900px) {
	}
`;
export const DescriptionUnderstand = styled.p`
	font-size: 25px;
	font-weight: 400;
	line-height: 47px;

	@media screen and (max-width: 900px) {
	}
`;
export const DifferentialContainer = styled.div`
	width: 100%;
	padding: 50px 0;
	font-family: "roboto";
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: ${colors.backgroundServices};
`;
export const DifferentialContent = styled.div`
	max-width: 1250px;
	width: 100%;
	font-family: "roboto";
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;
export const TitleDifferential = styled.h1`
	color: ${colors.blueMenu};
	text-align: center;
	font-size: 35px;
	font-weight: 700;
	font-family: "roboto";
	margin: 0 auto;
	width: 100%;

	@media screen and (max-width: 900px) {
		font-size: 35px;
		width: 90%;
		margin: 40px auto 0 auto;
	}
`;
export const ImageDifferential = styled.img`
	width: 90%;

	@media screen and (max-width: 900px) {
	}
`;
