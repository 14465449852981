import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SteelFrameImage from "../../assets/produtoInterna/steel-frame.jpg";
import SteelFrameImage1 from "../../assets/produtoInterna/steel-frame-1.jpg";
import SteelFrameImage2 from "../../assets/produtoInterna/steel-frame-2.jpg";
import SteelFrameImage3 from "../../assets/produtoInterna/steel-frame-3.jpg";

import KnowImage from "../../assets/produtoInterna/know.jpg";
import RightImage from "../../assets/produtoInterna/right.png";
import {
	Aux,
	AuxImages,
	Block,
	DescriptionUnderstand,
	ImageSteelFrame,
	ImageSteelFrameBig,
	PerfiladeiraDrywallContainer,
	Title,
	DifferentialContainer,
	DifferentialContent,
	TitleDifferential,
	ImageDifferential,
	AuxText,
	ImageRight,
} from "./styles";
import ProductsLine from "../ProductsLine";

const base_api = process.env.REACT_APP_URL_API;
const base_url = process.env.REACT_APP_BASE_URL;

function ProductDetails() {
	const { url } = useParams();
	const [produto, setProduto] = useState({});

	useEffect(() => {
		fetch(`${base_api}/produto/${url}`)
			.then((res) => res.json())
			.then((prod) => setProduto(prod))
			.catch(console.log);
	}, []);

	return (
		<div>
			<PerfiladeiraDrywallContainer>
				<Title>{produto.titulo}</Title>
				<Aux>
					<Block>
						<ImageSteelFrameBig src={`${base_url}${produto.arquivo ?? ""}`} />
						{produto.fotos?.length > 0 ? (
							<AuxImages>
								{produto.fotos?.map((foto, i) => (
									<ImageSteelFrame
										src={`${base_url}${foto.arquivo ?? ""}`}
										key={i}
									/>
								))}
							</AuxImages>
						) : (
							""
						)}
					</Block>
					<Block>
						<DescriptionUnderstand
							dangerouslySetInnerHTML={{ __html: produto.descricao }}
						></DescriptionUnderstand>
					</Block>
				</Aux>
			</PerfiladeiraDrywallContainer>
			{produto.diferenciais ? (
				<DifferentialContainer>
					<DifferentialContent>
						<TitleDifferential>
							Conheça os diferencias deste equipamento
						</TitleDifferential>
						<Aux>
							<Block style={{ alignItems: "flex-start" }}>
								{Object.keys(produto.diferenciais).map((key, i) => (
									<AuxText key={i}>
										<ImageRight src={RightImage} />
										<p style={{ margin: 0 }}>
											{produto.diferenciais[key].titulo}
										</p>
									</AuxText>
								))}
							</Block>
							<Block>
								<ImageDifferential
									src={`${base_url}${produto.arquivo_diferencial ?? ""}`}
								/>
							</Block>
						</Aux>
					</DifferentialContent>
				</DifferentialContainer>
			) : (
				""
			)}
			{produto.video ? (
				<iframe
					width="100%"
					height="525"
					src={`https://www.youtube.com/embed/${produto.video}?controls=0`}
					title="YouTube video player"
					frameBorder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen
				></iframe>
			) : (
				""
			)}

			<ProductsLine />
		</div>
	);
}
export default ProductDetails;
