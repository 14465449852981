import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "pure-react-carousel/dist/react-carousel.es.css";
import { Image, AuxImages, ImageLink } from "./styles.js";

const base_api = process.env.REACT_APP_URL_API;

function Categorias() {
	const [categorias, setCategorias] = useState([]);

	useEffect(() => {
		fetch(`${base_api}/categorias`)
			.then((res) => res.json())
			.then((categorias) => setCategorias(categorias))
			.catch(console.log);
	}, []);
	return (
		<AuxImages>
			{categorias.map((item, i) => (
				<ImageLink href={item.url} key={i}>
					<Image src={item.arquivo} alt={item.titulo} key={i} />
				</ImageLink>
			))}
		</AuxImages>
	);
}
export default Categorias;
