import React, { useEffect, useState } from "react";
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { BannerContainer, ImageBanner, Link } from "./styles.js";

const base_api = process.env.REACT_APP_URL_API;

function Banner() {
	const [banners, setBanners] = useState([]);

	useEffect(() => {
		fetch(`${base_api}/banners`)
			.then((res) => res.json())
			.then((banners) => setBanners(banners))
			.catch(console.log);
	}, []);
	return (
		<BannerContainer>
			<CarouselProvider
				naturalSlideWidth={100}
				naturalSlideHeight={30}
				totalSlides={banners.length}
				isPlaying={true}
			>
				<Slider>
					{banners
						? banners.map((item, i) => (
								<Slide index={i} key={i}>
									<Link href={item.link} target={item.target}>
										<ImageBanner src={item.arquivo_desktop} />
									</Link>
								</Slide>
						  ))
						: "Carregando..."}
				</Slider>
			</CarouselProvider>
		</BannerContainer>
	);
}
export default Banner;
