import styled from "styled-components";
import colors from "../../styles/colors";

export const HeaderContainer = styled.div`
  width: 100%;
  height: 206px;
  display: flex;
  justify-content: center;
  background-color: ${colors.headerBackground};
  box-shadow: none;
  padding: 20px 0;

  @media screen and (max-width: 1180px) {
    .ant-menu {
      flex-wrap: wrap;
    }
  }

  .ant-menu {
    background-color: ${colors.headerBackground};
  }

  .ant-menu-item {
    font-family: "roboto";
    width: 25%;
    height: 55px;
    margin: 0 18px 0 0;
    border-radius: 5px;
    background: ${colors.grayMenu};
    color: ${colors.black};
    font-size: 19px;
    font-weight: 700;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-menu-item::after {
    display: none;
  }

  .ant-menu-item-selected {
    font-family: "roboto";
    width: 25%;
    height: 55px;
    margin: 0 18px 0 0;
    border-radius: 5px;
    background: ${colors.blueMenu};
    color: ${colors.white}!important;
    font-size: 19px;
    font-weight: 700;
  }

  .ant-menu-item-selected::after {
    display: none;
  }

  .ant-menu-item-active {
    font-family: "roboto";
    width: 25%;
    height: 55px;
    margin: 0 18px 0 0;
    border-radius: 5px;
    background: ${colors.blueMenu}!important;
    color: ${colors.white}!important;
    font-size: 19px;
    font-weight: 700;
  }

  .ant-menu-item-active::after {
    display: none;
  }
`;

export const LogoImage = styled.img`
  width: 100%;
  max-width: 300px;
  margin: 0 40px 0 0;
`;

export const Indent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  @media screen and (max-width: 1180px) {
    justify-content: flex-start;
  }
`;
