import styled from "styled-components";
import colors from "../../styles/colors";

export const QuemSomosContainer = styled.div`
	max-width: 1020px;
	width: 100%;
	padding: 0;
	margin: 0 auto;
`;

export const Title = styled.h1`
	color: ${colors.titleText};
	text-align: center;
	background: ${colors.white};
	padding: 20px;
	border-radius: 24px;
	font-size: 43px;
	font-weight: 700;
	font-family: "roboto";
	margin: -90px auto 0 auto;
	z-index: 3000;
	position: relative;
	width: 100%;
	max-width: 450px;

	@media screen and (max-width: 900px) {
		font-size: 35px;
		width: 90%;
		margin: 40px auto 0 auto;
	}
`;

export const Description = styled.p`
	width: 100%;
	cursor: pointer;
	color: ${colors.titleText};
	text-align: center;
	font-size: 26px;
	font-weight: 400;
	line-height: 47px;
	font-family: "roboto";

	@media screen and (max-width: 900px) {
		font-size: 20px;
		width: 90%;
		margin: 25px auto 0 auto;
	}
`;

export const Link = styled.a`
	text-decoration: none;
	text-transform: uppercase;
	text-align: right;
	color: ${colors.grey};
	font-size: 20px;
	font-weight: 700;
	line-height: 27px;
	margin: 15px 0;
	display: flex;
	justify-content: flex-end;
	cursor: pointer;
	font-family: "roboto";

	@media screen and (max-width: 900px) {
		justify-content: center;
	}
`;
