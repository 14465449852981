import React from "react";
import "pure-react-carousel/dist/react-carousel.es.css";
import MissaoImage from "../../assets/missao.png";
import VisaoImage from "../../assets/visao.png";
import ValoresImage from "../../assets/valores.png";
import {
  SobreNosContainer,
  Title,
  Description,
  Aux,
  Block,
  ImageSobre,
  TitleBlock,
  DescriptionBlock,
  AuxImage,
} from "./styles.js";

function SobreNos() {
  return (
    <SobreNosContainer>
      <Title>SOBRE NÓS</Title>
      <Description>
        Situada no município de Limeira - SP, a empresa foi fundada em 1993,
        voltada para a construção de máquinas e equipamentos especiais para
        perfilação, corte e dobras de chapas. Sempre firmando parcerias com
        empresas sérias e altamente capacitadas para desenvolvimento de seus
        produtos, hoje tem como principal característica a construção de
        equipamentos personalizados que atendem a necessidade específica de seus
        clientes.
      </Description>
      <Description>
        A Metalso é referência no desenvolvimento de Perfiladeiras para Drywall
        (Guias / Montantes / Tabica / Cantoneira/ F530 ), Steel Frame, Telhas
        Trapezoidais, Onduladas e Zipadas, Linha de Corte Longitudinal
        (Slliters) e demais equipamentos exclusivos para diversos seguimentos.
      </Description>
      <Aux>
        <Block>
          <AuxImage>
            <ImageSobre src={MissaoImage} />
          </AuxImage>
          <TitleBlock>Missão</TitleBlock>
          <DescriptionBlock>
            Fabricar equipamentos de alta tecnologia, produtividade e
            confiabilidade buscando constante aperfeiçoamento.
          </DescriptionBlock>
        </Block>
        <Block>
          <AuxImage>
            <ImageSobre src={VisaoImage} />
          </AuxImage>
          <TitleBlock>Visão</TitleBlock>
          <DescriptionBlock>
            Ser referência na fabricação de maquinários siderúrgicos especiais,
            produzindo equipamentos de qualidade e buscando a satisfação total
            de cada cliente atendido.
          </DescriptionBlock>
        </Block>
        <Block>
          <AuxImage>
            <ImageSobre src={ValoresImage} />
          </AuxImage>
          <TitleBlock>Valores</TitleBlock>
          <DescriptionBlock>
            Comprometimento com o cliente; Alta tecnologia; Eficiência em venda
            e pós-venda; Suporte técnico especializado; Confiabilidade; Garantia
            de entrega e funcionamento.
          </DescriptionBlock>
        </Block>
      </Aux>
    </SobreNosContainer>
  );
}
export default SobreNos;
